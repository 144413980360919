@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Devanagari:wght@100;200;300;400;500;600;700;800;900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
    margin: 0;
    padding: 0;
    font-family: 'Rubik', sans-serif;
}

:root {
    --primary: #eab70f;
    --secondary: rgb(0, 168, 59);
    --gradient: 90deg, #fff, #000;
}

a{
    text-decoration: none;
}

.text-ear{
    color: var(--primary);
}

.text-justify{
    text-align: justify;
}

.react-responsive-modal-root{
    z-index: 1201 !important;
}

body::-webkit-scrollbar{
    width: 8px;
}

body::-webkit-scrollbar-thumb{
    border-radius: 25px;
    background-color: var(--primary);
    transition: 0.7s;
}

body::-webkit-scrollbar-track{
    background-color: rgb(226, 232, 236, 0);
    border-radius: 10px;
}


/* ################ FORMAT INPUTS ################## */


.inputBox{
    position: relative;
    margin-top: 1rem;
}
.inputBox input, .inputBox select{
    position: relative;
    width: 100px;
    padding: .35rem .75rem !important;
    background: transparent;
    outline:none;
    border: 1px solid #dee2e6;
    border-radius: .375REM;
    color: #000;
    font-size: 1rem;
    transition: .5s;
    z-index: 10;
}

.inputBox span{
    position: absolute;
    left: 0;
    margin: .35rem .75rem;
    pointer-events: none;
    color: #8f8f8f;
    font-size: 1rem;
    transition: .5s;
}

form .inputBox select:valid~span, 
form .inputBox select:focus~span,
form .inputBox input:valid~span, 
form .inputBox input:focus~span{
    color: var(--primary);
    font-size: .9rem;
    background-color: white;
    margin: .35rem .75rem;
    margin-bottom: 1rem;
    z-index: 10;
    transform: translateY(-1.1rem);
}


.inputBox i{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    border-radius: .375rem;
    overflow: hidden; 
    pointer-events: none;
}

.inputBox input:valid ~ i, 
.inputBox input:focus ~ i{
    height: 44px;
}

.inputFile label{
    border-radius: 10px;
    border: 2px dashed;
    border-color: #00000082;
    cursor: pointer;
}

input[type="text"]:focus, input[type="email"]:focus, .form-control:focus{
    box-shadow: none;
    border: 1px solid var(--primary);
}

button[type="submit"]{
    background: var(--primary) !important;
    border: 2px var(--primary) solid !important;
    color: white;
    transition: all .3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

button[type="submit"]:hover{
    background: #fff !important;
    color: #000;
    transform: translateY(-3px);
}

button[type="reset"]{
    background: #fff !important;
    border: 2px var(--primary) solid !important;
    color: #000;
    transition: all .3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

button[type="reset"]:hover{
    background: var(--primary) !important;
    border: 2px var(--primary) solid !important;
    color: white;
    transform: translateY(-3px);
}








/* ########### SIDEBAR ######### */

.sidebar{
    width: 240px;
    overflow: hidden;
    transition-duration: 200ms;
    flex-shrink: 0;
    white-space: nowrap;
    transition-property: width;
    transition-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
}

.sidebar-container{
    border-right: 1px solid #E2E8F0;
    width: 240px;
    overflow: inherit;
    background: #fff;
    top: 0;
    flex: 1 0 auto;
    width: inherit;
    height: 100%;
    display: flex;
    outline: 0;
    /* z-index: 1200; */
    position: fixed;
    overflow-y: auto;
    flex-direction: column;
    -webkit-overflow-scrolling: touch;
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.sidebar-container .hide-arrow{
    display: none;
}

@media screen and (min-width: 767px) {
    .sidebar-container .hide-arrow{
        top: 45px;
        right: -7px;
        width: 24px;
        border: 1px solid #E2E8F0;
        height: 24px;
        display: flex;
        padding: 0;
        z-index: 9999;
        position: absolute;
        background: #fff;
        box-shadow: 0 5px 12px #0D0D3630;
        box-sizing: content-box;
        align-items: center;
        border-radius: 50% 0% 0% 50%;
    }
}

.sidebar-container .hide-arrow button{
    width: 24px;
    height: 24px;
    padding: 0;
    flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.54);
    overflow: visible;
    font-size: 1.2857142857142856rem;
    text-align: center;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
    border: 0;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    outline: 0;
    position: relative;
    align-items: center;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
}

.sidebar-container .log-out{
    margin-top: auto;
}

.sidebar-container .log-out >div{
    display: flex;
    flex-direction: row;
    height: 87px;
    color: rgb(50, 50, 92);
    width: 100%;
    cursor: pointer;
    border-top: 1px solid rgb(226, 232, 240);
    background: rgb(249, 250, 255);
}

.sidebar-container .sidebar-menu{
    display: contents;
    overflow: hidden overlay;
}

.sidebar-container .sidebar-menu .logo-brand {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-left: 32px;
    min-height: 22px;
    padding: 36px 0px 35px;
    color: rgb(50, 50, 92);
}

.sidebar-menu .menu{
    overflow: hidden overlay;
}

.menu .nav-link.active, .menu .nav-link:hover{
    background: linear-gradient(90deg, rgba(246,246,246,1) 0%, rgba(231,251,255,1) 50%, rgba(211,239,245,1) 100%);
}







/* ############## MAIN ############## */

.main{
    min-height: 100vh;
    -webkit-box-flex: 1;
    flex-grow: 1;
}

.main .main-head{
    position: sticky;
    top: 0px;
    width: 100%;
    z-index: 10;
    background: rgb(251, 251, 255);
    display: flex;
    -webkit-box-pack: center;
    justify-content: space-between;
    box-shadow: rgba(84, 87, 255, 0.05) 0px 6px 12px;
    border-bottom: 1px solid rgb(220, 221, 255);
    -webkit-box-align: center;
    align-items: center;
    z-index: 999;
    flex-wrap: wrap;
}

.main .main-body{
    padding-top: 50px;
    padding-bottom: 50px;
    overflow-x: hidden;
    min-height: 96vh;
    background: url(../../assets/img/2488036_346596-PAVGP3-447-02.svg) fixed;
    background-size: cover;
    background-repeat: no-repeat;

}







/* ############## ADD ############### */

.ql-container.ql-snow{
    min-height: 20rem;
}







/* ############## SHOW ############### */

.img-show{
    width: 5rem;
    height: 5rem;
}

.title-show{
    width: calc(100vw - 7rem - 240px - 80px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

@media screen and (max-width: 767px) {
    .title-show{
        width: calc(100vw - 7rem - 70px);
    }
}

.accordion-item{
    margin: 0;
    border: 0;
    display: flex;
    flex: row;
}

.accordion-body{
    padding: 0;
}

.accordion-button{
    padding: 0;
    display: flex;
    justify-content: center;
}

.accordion-button:not(.collapsed) {
    background-color: #fff;
}

.accordion-button::after{
    display: none;
}

.accordion-header button:focus {
    border-color: transparent !important;
    box-shadow: none !important;
}








/* ############ HOME ###############  */

.chart-actu{
    max-width: 80vw;
    max-height: 90vh;
}








/* ############ SEARCH BAR ###############  */

.overlay{
    position: fixed;
    display: flex;
    padding-top: 50px;
    align-items: top;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1300;
    background: rgba(1, 1, 1, 0.8);
}

#results{
    overflow: auto;
    max-height: calc(100vh - 50px - 0.5rem - 3rem - 1rem - 5rem);
}








/* ############ LOADING ###############  */
.loading-page{
    width: 100vw;
    height: 100vh;
}







/* ############ LOGIN ###############  */
.login-bg{
    background-color: #ecdfcb;
}

.login{
    min-height: 100vh;
}

.login-img{
    max-height: 80vh;
    box-shadow: inset gray 0px 0px 60px -12px; 
}

.login-brand{
    max-width: 8rem;
}









/* ############ ACCOUNT ###############  */
.account-page{
    min-height: 60vh;
}